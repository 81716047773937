// @file calculates the time in hrs/days/months/yrs between current date and past/future date
import { getUnderscoredCurrentLocale } from '@@/bits/current_locale'
import strings from '@@/bits/relative_time_strings'

type TimeKeyTuple = [string, number]
const timeKey = function (timeInMs: number): TimeKeyTuple {
  const seconds = timeInMs / 1000
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = hours / 24
  const months = days / 30
  const years = days / 365

  if (seconds < 45) {
    return ['seconds', seconds]
  }
  if (seconds < 90) {
    return ['minute', minutes]
  }
  if (minutes < 45) {
    return ['minutes', minutes]
  }
  if (minutes < 90) {
    return ['hour', hours]
  }
  if (hours < 24) {
    return ['hours', hours]
  }
  if (hours < 36) {
    return ['day', days]
  }
  if (days < 28) {
    return ['days', days]
  }
  if (days < 45) {
    return ['month', months]
  }
  if (months < 12) {
    return ['months', months]
  }
  if (months < 18) {
    return ['year', years]
  }
  return ['years', years]
}

const getTime = (date: string | number | Date): number => {
  if (date == undefined || date == null) throw new Error('date is blank')
  const time = new Date(date).getTime()
  if (isNaN(time)) throw new Error('date is invalid')
  return time
}

type RelativeTimeFormat = 'short' | 'long'
const relativeTime = function (
  pastOrFutureDate: string | number | Date,
  format: RelativeTimeFormat,
  nowDate: string | null = null,
): string {
  const pastOrFutureDateTimestamp = getTime(pastOrFutureDate)
  const nowDateTimestamp = nowDate ? getTime(nowDate) : new Date().getTime()
  const elapsedTimeInMs = nowDateTimestamp - pastOrFutureDateTimestamp // future elapsed times are negative

  const [keyName, keyValue] = timeKey(Math.abs(elapsedTimeInMs))
  const localeToUse = getUnderscoredCurrentLocale()

  if (format == 'short') {
    const localeData = strings[`${localeToUse}-short`] || strings[localeToUse] || strings['en-short']
    const stringOrStringFunction = localeData[keyName]
    const uninterpolatedString =
      typeof stringOrStringFunction === 'function' ? stringOrStringFunction(keyValue) : stringOrStringFunction
    return uninterpolatedString.replace(/%d/i, `${Math.round(keyValue)}`)
  } else if (format == 'long') {
    const localeData = strings[localeToUse] || strings.en
    const stringOrStringFunction = localeData[keyName]
    const uninterpolatedString =
      typeof stringOrStringFunction === 'function' ? stringOrStringFunction(keyValue) : stringOrStringFunction
    const unprefixedString = uninterpolatedString.replace(/%d/i, `${Math.round(keyValue)}`)
    const stringParts = [unprefixedString]
    if (elapsedTimeInMs > 0) {
      if (localeData.prefixAgo) stringParts.unshift(localeData.prefixAgo)
      if (localeData.suffixAgo) stringParts.push(localeData.suffixAgo)
    } else {
      if (localeData.prefixFromNow) stringParts.unshift(localeData.prefixFromNow)
      if (localeData.suffixFromNow) stringParts.push(localeData.suffixFromNow)
    }
    return stringParts.join(' ')
  } else {
    throw new Error('Valid formats are short and long')
  }
}

export default relativeTime
export { getTime }
