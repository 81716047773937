// @file Support international languages for relative_time.ts
type ElapsedTimeToPhraseFn = (num: number) => string
type ElapsedTimeToCzechPhraseFn = (num1: number, num2: number) => string
type ElapsedTimeToPhrase = string | ElapsedTimeToPhraseFn | ElapsedTimeToCzechPhraseFn
interface LocaleManifest {
  prefixAgo: string
  prefixFromNow: string
  suffixAgo: string
  suffixFromNow: string
  second?: ElapsedTimeToPhrase
  seconds: ElapsedTimeToPhrase
  minute: ElapsedTimeToPhrase
  minutes: ElapsedTimeToPhrase
  hour: ElapsedTimeToPhrase
  hours: ElapsedTimeToPhrase
  day: ElapsedTimeToPhrase
  days: ElapsedTimeToPhrase
  month: ElapsedTimeToPhrase
  months: ElapsedTimeToPhrase
  year: ElapsedTimeToPhrase
  years: ElapsedTimeToPhrase
  wordSeparator?: string
  numbers?: number[]
}

const strings: { [key: string]: LocaleManifest } = {}

// Traditional Chinese
strings['zh_TW'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '之前',
  suffixFromNow: '之後',
  seconds: '不到1分鐘',
  minute: '大約1分鐘',
  minutes: '%d分鐘',
  hour: '大約1小時',
  hours: '%d小時',
  day: '大約1天',
  days: '%d天',
  month: '大約1個月',
  months: '%d個月',
  year: '大約1年',
  years: '%d年',
  numbers: [],
  wordSeparator: '',
}

// Simplified Chinese
strings['zh_CN'] = {
  prefixAgo: '',
  prefixFromNow: '从现在开始',
  suffixAgo: '之前',
  suffixFromNow: '',
  seconds: '不到1分钟',
  minute: '1分钟',
  minutes: '%d分钟',
  hour: '1小时',
  hours: '%d小时',
  day: '1天',
  days: '%d天',
  month: '1个月',
  months: '%d月',
  year: '1年',
  years: '%d年',
  numbers: [],
  wordSeparator: '',
}

// Vietnamese
strings['vi'] = {
  prefixAgo: 'cách đây',
  prefixFromNow: '',
  suffixAgo: 'trước',
  suffixFromNow: 'tới',
  seconds: 'dưới một phút',
  minute: 'một phút',
  minutes: '%d phút',
  hour: 'một tiếng',
  hours: '%d tiếng',
  day: 'một ngày',
  days: '%d ngày',
  month: 'một tháng',
  months: '%d tháng',
  year: 'một năm',
  years: '%d năm',
  wordSeparator: ' ',
  numbers: [],
}

// Vietnamese shortened
strings['vi-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '1s',
  minute: '1ph',
  minutes: '%dph',
  hour: '1g',
  hours: '%dg',
  day: '1ngày',
  days: '%dngày',
  month: '1thg',
  months: '%dthg',
  year: '1năm',
  years: '%dnăm',
  wordSeparator: ' ',
  numbers: [],
}

// Urdu
strings['ur'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'پہلے',
  suffixFromNow: 'اب سے',
  seconds: 'کچھ سیکنڈز',
  minute: 'تقریباً ایک منٹ',
  minutes: '%d منٹ',
  hour: 'تقریباً ایک گھنٹہ',
  hours: 'تقریباً  %d گھنٹے',
  day: 'ایک دن',
  days: '%d دن',
  month: 'تقریباً ایک مہینہ',
  months: '%d مہینے',
  year: 'تقریباً ایک سال',
  years: '%d سال',
  wordSeparator: ' ',
  numbers: [],
}

// Ukrainian
strings['uk'] = (function (): LocaleManifest {
  const numpf = function (n: number, f: string, s: string, t: string): string {
    const n10 = n % 10
    // f - 1, 21, 31, ...
    // s - 2-4, 22-24, 32-34 ...
    // t - 5-20, 25-30, ...
    if (n10 === 1 && (n === 1 || n > 20)) {
      return f
    } else if (n10 > 1 && n10 < 5 && (n > 20 || n < 10)) {
      return s
    } else {
      return t
    }
  }
  return {
    prefixAgo: '',
    prefixFromNow: 'через',
    suffixAgo: 'тому',
    suffixFromNow: '',
    seconds: 'менше хвилини',
    minute: 'хвилина',
    minutes: function (value: number): string {
      return numpf(value, '%d хвилина', '%d хвилини', '%d хвилин')
    },
    hour: 'година',
    hours: function (value: number): string {
      return numpf(value, '%d година', '%d години', '%d годин')
    },
    day: 'день',
    days: function (value: number): string {
      return numpf(value, '%d день', '%d дні', '%d днів')
    },
    month: 'місяць',
    months: function (value: number): string {
      return numpf(value, '%d місяць', '%d місяці', '%d місяців')
    },
    year: 'рік',
    years: function (value: number): string {
      return numpf(value, '%d рік', '%d роки', '%d років')
    },
  }
})()

// Turkish
strings['tr'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'önce',
  suffixFromNow: '',
  seconds: '1 dakikadan',
  minute: '1 dakika',
  minutes: '%d dakika',
  hour: '1 saat',
  hours: '%d saat',
  day: '1 gün',
  days: '%d gün',
  month: '1 ay',
  months: '%d ay',
  year: '1 yıl',
  years: '%d yıl',
}

// Turkish shortened
strings['tr-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '1sn',
  minute: '1d',
  minutes: '%dd',
  hour: '1s',
  hours: '%ds',
  day: '1g',
  days: '%dg',
  month: '1ay',
  months: '%day',
  year: '1y',
  years: '%dy',
  wordSeparator: ' ',
  numbers: [],
}

// Tagalog / Filipino
strings['tl'] = {
  prefixFromNow: 'sa loob ng',
  prefixAgo: '',
  suffixFromNow: '',
  suffixAgo: ' ang nakalipas',
  seconds: 'ilang segundo',
  minute: 'isang minuto',
  minutes: '%d minuto',
  hour: 'isang oras',
  hours: '%d oras',
  day: 'isang araw',
  days: '%d araw',
  month: 'isang buwan',
  months: '%d buwan',
  year: 'isang taon',
  years: '%d taon',
}

// Thai
strings['th'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'ที่แล้ว',
  suffixFromNow: 'จากตอนนี้',
  seconds: 'น้อยกว่าหนึ่งนาที',
  minute: 'ประมาณหนึ่งนาที',
  minutes: '%d นาที',
  hour: 'ประมาณหนึ่งชั่วโมง',
  hours: 'ประมาณ %d ชั่วโมง',
  day: 'หนึ่งวัน',
  days: '%d วัน',
  month: 'ประมาณหนึ่งเดือน',
  months: '%d เดือน',
  year: 'ประมาณหนึ่งปี',
  years: '%d ปี',
  wordSeparator: '',
  numbers: [],
}

// Swedish
strings['sv'] = {
  prefixAgo: 'för',
  prefixFromNow: 'om',
  suffixAgo: 'sedan',
  suffixFromNow: '',
  seconds: 'mindre än en minut',
  minute: 'en minut',
  minutes: '%d minuter',
  hour: 'en timme',
  hours: '%d timmar',
  day: 'en dag',
  days: '%d dagar',
  month: 'en månad',
  months: '%d månader',
  year: 'ett år',
  years: '%d år',
}

// Serbian
strings['sr'] = (function (): LocaleManifest {
  const numpf = function (n: number, f: string, s: string, t: string): string {
    let n10
    n10 = void 0
    n10 = n % 10
    if (n10 === 1 && (n === 1 || n > 20)) {
      return f
    } else if (n10 > 1 && n10 < 5 && (n > 20 || n < 10)) {
      return s
    } else {
      return t
    }
  }
  return {
    prefixAgo: 'пре',
    prefixFromNow: 'за',
    suffixAgo: '',
    suffixFromNow: '',
    second: 'секунд',
    seconds: function (value: number): string {
      return numpf(value, '%d секунд', '%d секунде', '%d секунди')
    },
    minute: 'један минут',
    minutes: function (value: number): string {
      return numpf(value, '%d минут', '%d минута', '%d минута')
    },
    hour: 'један сат',
    hours: function (value: number): string {
      return numpf(value, '%d сат', '%d сата', '%d сати')
    },
    day: 'један дан',
    days: function (value): string {
      return numpf(value, '%d дан', '%d дана', '%d дана')
    },
    month: 'месец дана',
    months: function (value: number): string {
      return numpf(value, '%d месец', '%d месеца', '%d месеци')
    },
    year: 'годину дана',
    years: function (value: number): string {
      return numpf(value, '%d годину', '%d године', '%d година')
    },
    wordSeparator: '',
  }
})()

// Slovak
strings['sk'] = {
  prefixAgo: 'pred',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'menej než minútou',
  minute: 'minútou',
  minutes: '%d minútami',
  hour: 'hodinou',
  hours: '%d hodinami',
  day: '1 dňom',
  days: '%d dňami',
  month: '1 mesiacom',
  months: '%d mesiacmi',
  year: '1 rokom',
  years: '%d rokmi',
}

// Russian
strings['ru'] = (function (): LocaleManifest {
  const numpf = function (n: number, f: string, s: string, t: string): string {
    const n10 = n % 10
    // f - 1, 21, 31, ...
    // s - 2-4, 22-24, 32-34 ...
    // t - 5-20, 25-30, ...
    if (n10 === 1 && (n === 1 || n > 20)) {
      return f
    } else if (n10 > 1 && n10 < 5 && (n > 20 || n < 10)) {
      return s
    } else {
      return t
    }
  }
  return {
    prefixAgo: '',
    prefixFromNow: 'через',
    suffixAgo: 'назад',
    suffixFromNow: '',
    seconds: 'меньше минуты',
    minute: 'минуту',
    minutes: function (value: number): string {
      return numpf(value, '%d минута', '%d минуты', '%d минут')
    },
    hour: 'час',
    hours: function (value: number): string {
      return numpf(value, '%d час', '%d часа', '%d часов')
    },
    day: 'день',
    days: function (value: number): string {
      return numpf(value, '%d день', '%d дня', '%d дней')
    },
    month: 'месяц',
    months: function (value: number): string {
      return numpf(value, '%d месяц', '%d месяца', '%d месяцев')
    },
    year: 'год',
    years: function (value: number): string {
      return numpf(value, '%d год', '%d года', '%d лет')
    },
  }
})()

// Romanian
strings['ro'] = {
  prefixAgo: 'acum',
  prefixFromNow: 'in timp de',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'mai putin de un minut',
  minute: 'un minut',
  minutes: '%d minute',
  hour: 'o ora',
  hours: '%d ore',
  day: 'o zi',
  days: '%d zile',
  month: 'o luna',
  months: '%d luni',
  year: 'un an',
  years: '%d ani',
}

// Portuguese Brasil
strings['pt_BR'] = {
  prefixAgo: 'há',
  prefixFromNow: 'em',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'alguns segundos',
  minute: 'um minuto',
  minutes: '%d minutos',
  hour: 'uma hora',
  hours: '%d horas',
  day: 'um dia',
  days: '%d dias',
  month: 'um mês',
  months: '%d meses',
  year: 'um ano',
  years: '%d anos',
}

strings['pt_BR-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '1m',
  minute: '1m',
  minutes: '%dm',
  hour: '1h',
  hours: '%dh',
  day: '1d',
  days: '%dd',
  month: '1M',
  months: '%dM',
  year: '1a',
  years: '%da',
  wordSeparator: ' ',
  numbers: [],
}

// Polish
strings['pl'] = (function (): LocaleManifest {
  const numpf = function (n: number, s: string, t: string): string {
    const n10 = n % 10
    // s - 2-4, 22-24, 32-34 ...
    // t - 5-21, 25-31, ...
    if (n10 > 1 && n10 < 5 && (n > 20 || n < 10)) {
      return s
    } else {
      return t
    }
  }
  return {
    prefixAgo: '',
    prefixFromNow: 'za',
    suffixAgo: 'temu',
    suffixFromNow: '',
    seconds: 'mniej niż minutę',
    minute: 'minutę',
    minutes: function (value: number): string {
      return numpf(value, '%d minuty', '%d minut')
    },
    hour: 'godzinę',
    hours: function (value: number): string {
      return numpf(value, '%d godziny', '%d godzin')
    },
    day: 'dzień',
    days: '%d dni',
    month: 'miesiąc',
    months: function (value: number): string {
      return numpf(value, '%d miesiące', '%d miesięcy')
    },
    year: 'rok',
    years: function (value: number): string {
      return numpf(value, '%d lata', '%d lat')
    },
  }
})()

// Norwegian
strings['no'] = {
  prefixAgo: 'for',
  prefixFromNow: 'om',
  suffixAgo: 'siden',
  suffixFromNow: '',
  seconds: 'mindre enn et minutt',
  minute: 'et minutt',
  minutes: '%d minutter',
  hour: 'en time',
  hours: '%d timer',
  day: 'en dag',
  days: '%d dager',
  month: 'en måned',
  months: '%d måneder',
  year: 'et år',
  years: '%d år',
}

// Dutch
strings['nl'] = {
  prefixAgo: '',
  prefixFromNow: 'over',
  suffixAgo: 'geleden',
  suffixFromNow: '',
  seconds: 'minder dan een minuut',
  minute: 'een minuut',
  minutes: '%d minuten',
  hour: 'een uur',
  hours: '%d uur',
  day: 'een dag',
  days: '%d dagen',
  month: 'een maand',
  months: '%d maanden',
  year: 'een jaar',
  years: '%d jaar',
  wordSeparator: ' ',
  numbers: [],
}

// Malaysian
strings['ms'] = {
  prefixFromNow: 'dalam ',
  prefixAgo: '',
  suffixFromNow: '',
  suffixAgo: ' yang lalu',
  seconds: 'beberapa saat',
  minute: 'seminit',
  minutes: '%d minit',
  hour: 'sejam',
  hours: '%d jam',
  day: 'sehari',
  days: '%d hari',
  month: 'sebulan',
  months: '%d bulan',
  year: 'setahun',
  years: '%d tahun',
}

//Latvian
strings['lv'] = {
  prefixAgo: 'pirms',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: 'no šī brīža',
  seconds: '%d sek.',
  minute: 'min.',
  minutes: '%d min.',
  hour: 'st.',
  hours: '%d st.',
  day: '1 d.',
  days: '%d d.',
  month: 'mēnesis.',
  months: '%d mēnesis.',
  year: 'gads',
  years: '%d gads',
  wordSeparator: ' ',
  numbers: [],
}

// Korean
strings['ko'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '전',
  suffixFromNow: '후',
  seconds: '1분',
  minute: '1분',
  minutes: '%d분',
  hour: '1시간',
  hours: '%d시간',
  day: '하루',
  days: '%d일',
  month: '1개월',
  months: '%d개월',
  year: '1년',
  years: '%d년',
  wordSeparator: ' ',
  numbers: [],
}

// Japanese
strings['ja'] = {
  prefixAgo: '',
  prefixFromNow: '今から',
  suffixAgo: '前',
  suffixFromNow: '後',
  seconds: '1 分未満',
  minute: '1 分',
  minutes: '%d 分',
  hour: '1 時間',
  hours: '%d 時間',
  day: '1 日',
  days: '%d 日',
  month: '1 ヶ月',
  months: '%d ヶ月',
  year: '1 年',
  years: '%d 年',
  wordSeparator: '',
}

// Italian
strings['it'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'fa',
  suffixFromNow: 'da ora',
  seconds: 'meno di un minuto',
  minute: 'circa un minuto',
  minutes: '%d minuti',
  hour: "circa un'ora",
  hours: 'circa %d ore',
  day: 'un giorno',
  days: '%d giorni',
  month: 'circa un mese',
  months: '%d mesi',
  year: 'circa un anno',
  years: '%d anni',
}

strings['it-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '1m',
  minute: '1m',
  minutes: '%dm',
  hour: '1h',
  hours: '%dh',
  day: '1g',
  days: '%dg',
  month: '1me',
  months: '%dme',
  year: '1a',
  years: '%da',
  wordSeparator: ' ',
  numbers: [],
}

// Indonesian
strings['id'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'yang lalu',
  suffixFromNow: 'dari sekarang',
  seconds: 'kurang dari semenit',
  minute: 'satu menit',
  minutes: '%d menit',
  hour: 'sejam',
  hours: '%d jam',
  day: 'sehari',
  days: '%d hari',
  month: 'sebulan',
  months: '%d bulan',
  year: 'setahun',
  years: '%d tahun',
}

// Hungarian
strings['hu'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'kevesebb mint egy perce',
  minute: 'körülbelül egy perce',
  minutes: '%d perce',
  hour: 'körülbelül egy órája',
  hours: 'körülbelül %d órája',
  day: 'körülbelül egy napja',
  days: '%d napja',
  month: 'körülbelül egy hónapja',
  months: '%d hónapja',
  year: 'körülbelül egy éve',
  years: '%d éve',
}

// Croatian
strings['hr'] = (function (): LocaleManifest {
  const numpf = function (n: number, f: string, s: string, t: string): string {
    const n10 = n % 10
    if (n10 === 1 && (n === 1 || n > 20)) {
      return f
    } else if (n10 > 1 && n10 < 5 && (n > 20 || n < 10)) {
      return s
    } else {
      return t
    }
  }

  return {
    prefixAgo: 'prije',
    prefixFromNow: 'za',
    suffixAgo: '',
    suffixFromNow: '',
    second: 'sekundu',
    seconds: function (value): string {
      return numpf(value, '%d sekundu', '%d sekunde', '%d sekundi')
    },
    minute: 'oko minutu',
    minutes: function (value): string {
      return numpf(value, '%d minutu', '%d minute', '%d minuta')
    },
    hour: 'oko jedan sat',
    hours: function (value): string {
      return numpf(value, '%d sat', '%d sata', '%d sati')
    },
    day: 'jedan dan',
    days: function (value): string {
      return numpf(value, '%d dan', '%d dana', '%d dana')
    },
    month: 'mjesec dana',
    months: function (value): string {
      return numpf(value, '%d mjesec', '%d mjeseca', '%d mjeseci')
    },
    year: 'prije godinu dana',
    years: function (value): string {
      return numpf(value, '%d godinu', '%d godine', '%d godina')
    },
    wordSeparator: ' ',
  }
})()

// Hindi
strings['hi'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'पहले',
  suffixFromNow: 'में',
  seconds: 'कुछ ही क्षण',
  minute: 'एक मिनट',
  minutes: '%d मिनट',
  hour: 'एक घंटा',
  hours: '%d घंटे',
  day: 'एक दिन',
  days: '%d दिन',
  month: 'एक महीने',
  months: '%d महीने',
  year: 'एक वर्ष',
  years: '%d वर्ष',
}

// Hebrew
strings['he'] = {
  prefixAgo: 'לפני',
  prefixFromNow: 'עוד',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'פחות מדקה',
  minute: 'דקה',
  minutes: '%d דקות',
  hour: 'שעה',
  hours: function (number: number): string {
    if (number === 2) {
      return 'שעתיים'
    } else {
      return '%d שעות'
    }
  },
  day: 'יום',
  days: function (number: number): string {
    if (number === 2) {
      return 'יומיים'
    } else {
      return '%d ימים'
    }
  },
  month: 'חודש',
  months: function (number: number): string {
    if (number === 2) {
      return 'חודשיים'
    } else {
      return '%d חודשים'
    }
  },
  year: 'שנה',
  years: function (number: number): string {
    if (number === 2) {
      return 'שנתיים'
    } else {
      return '%d שנים'
    }
  },
}

// Galician
strings['gl'] = {
  prefixAgo: 'hai',
  prefixFromNow: 'dentro de',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'menos dun minuto',
  minute: 'un minuto',
  minutes: 'uns %d minutos',
  hour: 'unha hora',
  hours: '%d horas',
  day: 'un día',
  days: '%d días',
  month: 'un mes',
  months: '%d meses',
  year: 'un ano',
  years: '%d anos',
}

// French
strings['fr'] = {
  prefixAgo: 'il y a',
  prefixFromNow: "d'ici",
  suffixAgo: '',
  suffixFromNow: '',
  seconds: "moins d'une minute",
  minute: 'environ une minute',
  minutes: 'environ %d minutes',
  hour: 'environ une heure',
  hours: 'environ %d heures',
  day: 'environ un jour',
  days: 'environ %d jours',
  month: 'environ un mois',
  months: 'environ %d mois',
  year: 'un an',
  years: '%d ans',
}

strings['fr-short'] = {
  prefixAgo: 'il y a',
  prefixFromNow: "d'ici",
  suffixAgo: '',
  suffixFromNow: '',
  seconds: "moins d'une minute",
  minute: 'une minute',
  minutes: '%d minutes',
  hour: 'une heure',
  hours: '%d heures',
  day: 'un jour',
  days: '%d jours',
  month: 'un mois',
  months: '%d mois',
  year: 'un an',
  years: '%d ans',
}

// Finnish
strings['fi'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'sitten',
  suffixFromNow: 'tulevaisuudessa',
  seconds: 'alle minuutti',
  minute: 'minuutti',
  minutes: '%d minuuttia',
  hour: 'tunti',
  hours: '%d tuntia',
  day: 'päivä',
  days: '%d päivää',
  month: 'kuukausi',
  months: '%d kuukautta',
  year: 'vuosi',
  years: '%d vuotta',
}
// The above is not a great localization because one would usually
// write "2 days ago" in Finnish as "2 päivää sitten", however
// one would write "2 days into the future" as "2:n päivän päästä"
// which cannot be achieved with localization support this simple.
// This is because Finnish has word suffixes (attached directly
// to the end of the word). The word "day" is "päivä" in Finnish.
// As workaround, the above localizations will say
// "2 päivää tulevaisuudessa" which is understandable but
// not as fluent.

// Farsi / Persian
strings['fa'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'پیش',
  suffixFromNow: 'از حال',
  seconds: 'کمتر از یک دقیقه',
  minute: 'حدود یک دقیقه',
  minutes: '%d دقیقه',
  hour: 'حدود یک ساعت',
  hours: 'حدود %d ساعت',
  day: 'یک روز',
  days: '%d روز',
  month: 'حدود یک ماه',
  months: '%d ماه',
  year: 'حدود یک سال',
  years: '%d سال',
}

strings['fa-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '1دقیقه',
  minute: '1دقیقه',
  minutes: '%dدقیقه',
  hour: '1ساعت',
  hours: '%dساعت',
  day: '1روز',
  days: '%dروز',
  month: '1ماه',
  months: '%dماه',
  year: '1سال',
  years: '%dسال',
}

// Estonian
strings['et'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'tagasi',
  suffixFromNow: 'pärast',
  seconds: function (n, d): string {
    return d < 0 ? 'vähem kui minuti aja' : 'vähem kui minut aega'
  },
  minute: function (n, d): string {
    return d < 0 ? 'umbes minuti aja' : 'umbes minut aega'
  },
  minutes: function (n, d): string {
    return d < 0 ? '%d minuti' : '%d minutit'
  },
  hour: function (n, d): string {
    return d < 0 ? 'umbes tunni aja' : 'umbes tund aega'
  },
  hours: function (n, d): string {
    return d < 0 ? '%d tunni' : '%d tundi'
  },
  day: function (n, d): string {
    return d < 0 ? 'umbes päeva' : 'umbes päev'
  },
  days: '%d päeva',
  month: function (n, d): string {
    return d < 0 ? 'umbes kuu aja' : 'umbes kuu aega'
  },
  months: function (n, d): string {
    return d < 0 ? '%d kuu' : '%d kuud'
  },
  year: function (n, d): string {
    return d < 0 ? 'umbes aasta aja' : 'umbes aasta aega'
  },
  years: function (n, d): string {
    return d < 0 ? '%d aasta' : '%d aastat'
  },
}

// Spanish
strings['es'] = {
  prefixAgo: 'hace',
  prefixFromNow: 'dentro de',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'menos de un minuto',
  minute: 'un minuto',
  minutes: 'unos %d minutos',
  hour: 'una hora',
  hours: '%d horas',
  day: 'un día',
  days: '%d días',
  month: 'un mes',
  months: '%d meses',
  year: 'un año',
  years: '%d años',
}

strings['es-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '1m',
  minute: '1m',
  minutes: '%dm',
  hour: '1h',
  hours: '%dh',
  day: '1d',
  days: '%dd',
  month: '1me',
  months: '%dme',
  year: '1a',
  years: '%da',
  wordSeparator: ' ',
  numbers: [],
}

// English
strings['en'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'ago',
  suffixFromNow: 'from now',
  seconds: 'less than a minute',
  minute: 'about a minute',
  minutes: '%d minutes',
  hour: 'about an hour',
  hours: 'about %d hours',
  day: 'a day',
  days: '%d days',
  month: 'about a month',
  months: '%d months',
  year: 'about a year',
  years: '%d years',
  wordSeparator: ' ',
  numbers: [],
}

strings['en-exact'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'ago',
  suffixFromNow: 'from now',
  seconds: 'less than a minute',
  minute: '1 minute',
  minutes: '%d minutes',
  hour: '1 hour',
  hours: '%d hours',
  day: 'a day',
  days: '%d days',
  month: 'a month',
  months: '%d months',
  year: 'a year',
  years: '%d years',
  wordSeparator: ' ',
  numbers: [],
}

strings['en-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: '1m',
  minute: '1m',
  minutes: '%dm',
  hour: '1h',
  hours: '%dh',
  day: '1d',
  days: '%dd',
  month: '1mo',
  months: '%dmo',
  year: '1yr',
  years: '%dyr',
  wordSeparator: ' ',
  numbers: [],
}

// Greek
strings['el'] = {
  prefixAgo: 'πριν',
  prefixFromNow: 'σε',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'λιγότερο από ένα λεπτό',
  minute: 'ένα λεπτό',
  minutes: '%d λεπτά',
  hour: 'μία ώρα',
  hours: '%d ώρες',
  day: 'μία μέρα',
  days: '%d μέρες',
  month: 'ένα μήνα',
  months: '%d μήνες',
  year: 'ένα χρόνο',
  years: '%d χρόνια',
}

// German shortened
strings['de-short'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 's',
  minute: '1m',
  minutes: '%dm',
  hour: '1h',
  hours: '%dh',
  day: '1T.',
  days: '%dT.',
  month: '1Mt.',
  months: '%dMt.',
  year: '1J.',
  years: '%dJ.',
  wordSeparator: ' ',
  numbers: [],
}

// German
strings['de'] = {
  prefixAgo: 'vor',
  prefixFromNow: 'in',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'wenigen Sekunden',
  minute: 'etwa einer Minute',
  minutes: '%d Minuten',
  hour: 'etwa einer Stunde',
  hours: '%d Stunden',
  day: 'etwa einem Tag',
  days: '%d Tagen',
  month: 'etwa einem Monat',
  months: '%d Monaten',
  year: 'etwa einem Jahr',
  years: '%d Jahren',
}

// Danish
strings['da'] = {
  prefixAgo: 'for',
  prefixFromNow: 'om',
  suffixAgo: 'siden',
  suffixFromNow: '',
  seconds: 'mindre end et minut',
  minute: 'et minut',
  minutes: '%d minutter',
  hour: 'en time',
  hours: '%d timer',
  day: 'en dag',
  days: '%d dage',
  month: 'en måned',
  months: '%d måneder',
  year: 'et år',
  years: '%d år',
}

// Welsh
strings['cy'] = {
  prefixAgo: '',
  prefixFromNow: '',
  suffixAgo: 'yn ôl',
  suffixFromNow: 'o hyn',
  seconds: 'llai na munud',
  minute: 'funud',
  minutes: '%d munud',
  hour: 'tua awr',
  hours: '%d awr',
  day: 'y dydd',
  days: '%d diwrnod',
  month: 'tua mis',
  months: '%d mis',
  year: 'y flwyddyn',
  years: '%d blynedd',
  wordSeparator: ' ',
  numbers: [],
}

// Czech
/**
 * Different ways to describe past/present/future elapsed times
 * Second parameter is never passed into this function, since timekey function (in relative_time.ts) always returns an array with one key value (and not two)
 * Units of time for numbers (n) greater than 4 will have different suffixes (last index)
 * If elapsed time represents past time (... ago), function will return the first index
 */
strings['cs'] = ((): LocaleManifest => {
  const f = function (n: number, d: number, a: string[]): string {
    return a[d >= 0 ? 0 : a.length === 2 || n < 5 ? 1 : 2]
  }

  return {
    prefixAgo: 'před',
    prefixFromNow: 'za',
    suffixAgo: '',
    suffixFromNow: '',
    seconds: function (n: number, d: number): string {
      return f(n, d, ['méně než minutou', 'méně než minutu'])
    },
    minute: function (n: number, d: number): string {
      return f(n, d, ['minutou', 'minutu'])
    },
    minutes: function (n: number, d: number): string {
      return f(n, d, ['%d minutami', '%d minuty', '%d minut'])
    },
    hour: function (n: number, d: number): string {
      return f(n, d, ['hodinou', 'hodinu'])
    },
    hours: function (n: number, d: number): string {
      return f(n, d, ['%d hodinami', '%d hodiny', '%d hodin'])
    },
    day: function (n: number, d: number): string {
      return f(n, d, ['%d dnem', '%d den'])
    },
    days: function (n: number, d: number): string {
      return f(n, d, ['%d dny', '%d dny', '%d dní'])
    },
    month: function (n: number, d: number): string {
      return f(n, d, ['%d měsícem', '%d měsíc'])
    },
    months: function (n: number, d: number): string {
      return f(n, d, ['%d měsíci', '%d měsíce', '%d měsíců'])
    },
    year: function (n: number, d: number): string {
      return f(n, d, ['%d rokem', '%d rok'])
    },
    years: function (n: number, d: number): string {
      return f(n, d, ['%d lety', '%d roky', '%d let'])
    },
  }
})()

// Catalan
strings['ca'] = {
  prefixAgo: 'fa',
  prefixFromNow: "d'aquí",
  suffixAgo: '',
  suffixFromNow: '',
  seconds: "menys d'un minut",
  minute: 'un minut',
  minutes: '%d minuts',
  hour: 'una hora',
  hours: '%d hores',
  day: 'un dia',
  days: '%d dies',
  month: 'un mes',
  months: '%d mesos',
  year: 'un any',
  years: '%d anys',
}

// Bulgarian
strings['bg'] = {
  prefixAgo: 'преди',
  prefixFromNow: 'след',
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'по-малко от минута',
  minute: 'една минута',
  minutes: '%d минути',
  hour: 'един час',
  hours: '%d часа',
  day: 'един ден',
  days: '%d дни',
  month: 'един месец',
  months: '%d месеца',
  year: 'една година',
  years: '%d години',
}

// Arabic
strings['ar'] = (function (): LocaleManifest {
  const numpf = function (n: number, a: string[]): string {
    const plural = n === 0 ? 0 : n === 1 ? 1 : n === 2 ? 2 : n % 100 >= 3 && n % 100 <= 10 ? 3 : n % 100 >= 11 ? 4 : 5
    return a[plural]
  }
  return {
    prefixAgo: 'منذ',
    prefixFromNow: 'بعد',
    suffixAgo: '',
    suffixFromNow: '',
    second: function (value): string {
      return numpf(value, ['أقل من ثانية', 'ثانية واحدة', 'ثانيتين', '%d ثوانٍ', '%d ثانية', '%d ثانية'])
    },
    seconds: function (value): string {
      return numpf(value, ['أقل من ثانية', 'ثانية واحدة', 'ثانيتين', '%d ثوانٍ', '%d ثانية', '%d ثانية'])
    },
    minute: function (value): string {
      return numpf(value, ['أقل من دقيقة', 'دقيقة واحدة', 'دقيقتين', '%d دقائق', '%d دقيقة', 'دقيقة'])
    },
    minutes: function (value): string {
      return numpf(value, ['أقل من دقيقة', 'دقيقة واحدة', 'دقيقتين', '%d دقائق', '%d دقيقة', 'دقيقة'])
    },
    hour: function (value): string {
      return numpf(value, ['أقل من ساعة', 'ساعة واحدة', 'ساعتين', '%d ساعات', '%d ساعة', '%d ساعة'])
    },
    hours: function (value): string {
      return numpf(value, ['أقل من ساعة', 'ساعة واحدة', 'ساعتين', '%d ساعات', '%d ساعة', '%d ساعة'])
    },
    day: function (value): string {
      return numpf(value, ['أقل من يوم', 'يوم واحد', 'يومين', '%d أيام', '%d يومًا', '%d يوم'])
    },
    days: function (value): string {
      return numpf(value, ['أقل من يوم', 'يوم واحد', 'يومين', '%d أيام', '%d يومًا', '%d يوم'])
    },
    month: function (value): string {
      return numpf(value, ['أقل من شهر', 'شهر واحد', 'شهرين', '%d أشهر', '%d شهرًا', '%d شهر'])
    },
    months: function (value): string {
      return numpf(value, ['أقل من شهر', 'شهر واحد', 'شهرين', '%d أشهر', '%d شهرًا', '%d شهر'])
    },
    year: function (value): string {
      return numpf(value, ['أقل من عام', 'عام واحد', 'عامين', '%d أعوام', '%d عامًا', '%d عام'])
    },
    years: function (value): string {
      return numpf(value, ['أقل من عام', 'عام واحد', 'عامين', '%d أعوام', '%d عامًا', '%d عام'])
    },
  }
})()

export default strings
